<template>
    <div class="WrongTopic">
        <!-- 加载中 -->
        <Spin fix v-if="Subject_Loading">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
        <Head />
        <div class="middle">
            <div class="content">
                <div class="SubjectList">
                    <div class="List_item" v-for="(item,index) in SubjectList" :key="item.questionIndex">
                        <Topic :SubjectList="item"/>
                    </div>

                    <div class="last">已经是最后一题啦</div>
                    <div class="Ellipsis" v-if="SubjectList.length == 0">
                        <img src="@/assets/statistics_Ellipsis.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 右下角弹窗 -->
        <div class="fixed_btn">
            <div class="diss" @click="ClickDiss">
                <img src="@/assets/diss.png" alt="">
            </div>
        </div>
        <!-- 我要吐槽弹窗 -->
        <Dissmodal ref="Click_Diss" :title="Diss_title"/>
    </div>
</template>

<script>
// 页头
import Head from "@/components/head"
// 我要吐槽弹窗
import Dissmodal from "@/components/Dissmodal"
import Topic from "@/views/Review/Topic"
import { GetWrongQuestion, CollectQuestionPost} from "@/api/Review"
export default {
    name: 'WrongTopic',
    props: {},
    components: {
        Head,
        Dissmodal,
        Topic
    },
    data () {
       return {
            knowledgePointId: "",
            Subject_Loading: false,
            Diss_title: "题目反馈",
            // 题目列表
            SubjectList: [],
       }
    },
    created () {
        this.knowledgePointId = this.$route.query.id
        this.GetWrongQuestion()
    },
    mounted () {
    },
    methods: {
        // 题目收藏-获取模块下的题目
        GetWrongQuestion(){
            this.Subject_Loading = true
            GetWrongQuestion(this.knowledgePointId).then((res) =>{
                if(res.ret == 1){
                    // 题目列表
                    this.SubjectList = res.data
                }else{
                    console.log(res.msg)
                }
                this.Subject_Loading = false
            })
            .catch(err => {
                this.Subject_Loading = false
            });
        },

        // 点击我要吐槽
        ClickDiss(){
            this.$refs.Click_Diss.ClickDissmodal()
        },
    },
}
</script> 

<style lang='less' scoped>
    .WrongTopic{
        min-height: 100vh;
        background-color: #f8f8f8;
        position: relative;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1200px;
            .content{
                width: 1200px;
                min-height: 100vh;
                text-align: left;
                background: #FFFFFF;
                border-radius: 12px;
                margin-top: 80px;
                padding: 43px 30px 77px;
                .SubjectList{
                    .List_item{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                    .last{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                    .Ellipsis{
                        text-align: center;
                        margin-top: 150px;
                    }
                }
            }
        }
    }
    // 右下角按钮
    .fixed_btn{
        position: fixed;
        right: 40px;
        bottom: 92px;
        img{
            width: 80px;
            height: 80px;
        }
    }
    // 加载中
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
</style>
